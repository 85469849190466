/*
 * Copyright 2024 Larder Software Limited
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {
  configApiRef,
  createApiFactory,
  createComponentExtension,
  createPlugin,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { ragAiApiRef, RoadieRagAiClient } from './api';

export const ragAiPlugin = createPlugin({
  id: 'rag-ai',
  apis: [
    createApiFactory({
      api: ragAiApiRef,
      deps: {
        configApi: configApiRef,
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ configApi, discoveryApi, fetchApi, identityApi }) =>
        new RoadieRagAiClient({
          configApi,
          discoveryApi,
          fetchApi,
          identityApi,
        }),
    }),
  ],
});

export const RagModal = ragAiPlugin.provide(
  createComponentExtension({
    name: 'RagModal',
    component: {
      lazy: () =>
        import('./components/RagModal').then(m => m.UncontrolledRagModal),
    },
  }),
);

export const SidebarRagModal = ragAiPlugin.provide(
  createComponentExtension({
    name: 'SidebarRagModal',
    component: {
      lazy: () => import('./components/RagModal').then(m => m.SidebarRagModal),
    },
  }),
);
